export const JOURNAL_LIST_REQUEST = "JOURNAL_LIST_REQUEST";
export const JOURNAL_LIST_SUCCESS = "JOURNAL_LIST_SUCCESS";
export const JOURNAL_LIST_FAIL = "JOURNAL_LIST_FAIL";

export const JOURNAL_GROUP_REQUEST = "JOURNAL_GROUP_REQUEST";
export const JOURNAL_GROUP_SUCCESS = "JOURNAL_GROUP_SUCCESS";
export const JOURNAL_GROUP_FAIL = "JOURNAL_GROUP_FAIL";

export const JOURNAL_CREATE_REQUEST = "JOURNAL_CREATE_REQUEST";
export const JOURNAL_CREATE_SUCCESS = "JOURNAL_CREATE_SUCCESS";
export const JOURNAL_CREATE_FAIL = "JOURNAL_CREATE_FAIL";

export const JOURNAL_UPDATE_REQUEST = "JOURNAL_UPDATE_REQUEST";
export const JOURNAL_UPDATE_SUCCESS = "JOURNAL_UPDATE_SUCCESS";
export const JOURNAL_UPDATE_FAIL = "JOURNAL_UPDATE_FAIL";

export const JOURNAL_DELETE_REQUEST = "JOURNAL_DELETE_REQUEST";
export const JOURNAL_DELETE_SUCCESS = "JOURNAL_DELETE_SUCCESS";
export const JOURNAL_DELETE_FAIL = "JOURNAL_DELETE_FAIL";

export const JOURNAL_COMMENT_REQUEST = "JOURNAL_COMMENT_REQUEST";
export const JOURNAL_COMMENT_SUCCESS = "JOURNAL_COMMENT_SUCCESS";
export const JOURNAL_COMMENT_FAIL = "JOURNAL_COMMENT_FAIL";
