// IN PROGRESS TIM SHIM


import React from 'react'

const UserProfile = () => {

  return (
    <div style={{
        display: 'flex',
        // backgroundColor: 'red',
        height: '90vh',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }}>
        <div style={{
            display: 'flex',


        }}>
        </div>
        <div style={{
            



        }}>
        </div>
    </div>
  )
}

export default UserProfile